import { ListOptions } from '@/models/ListsModel';

export type ReportFiltersState = {
  machineFilter: string;
  startTimeFromFilter: string;
  startTimeToFilter: string;
};

export type ReportListState = {
  options: ListOptions;
};

export function initReportListState(): ReportListState {
  return {
    options: {
      groupBy: [],
      groupDesc: [],
      itemsPerPage: 10,
      multiSort: false,
      mustSort: false,
      page: 1,
      sortBy: [],
      sortDesc: [],
    },
  };
}

const reportListState = {
  options: initReportListState().options,
};

const reportListMutations = {
  setOptions(state: ReportListState, options: ListOptions): void {
    state.options = { ...state.options, ...options };
  },
};

export type ReportListMutations = typeof reportListMutations;
export type ReportListMutationsKey = keyof ReportListMutations;

export const report = {
  namespaced: true,
  state: reportListState,
  mutations: reportListMutations,
  actions: {},
  modules: {},
};
