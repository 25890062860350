import Vue from 'vue';
import Vuex from 'vuex';
import { auth } from './auth';
import { upload } from './upload';
import { user } from './user';
import { process } from './process';
import { report } from './report';

Vue.use(Vuex);

export type Snackbar = { state: boolean; text: string };

const simpleAppState = {
  isLinksDisabled: false,
  successSnackbar: { text: '', state: false },
  failedSnackbar: { text: '', state: false },
};

type SimpleAppState = typeof simpleAppState;

export interface AppState extends SimpleAppState {
  auth: typeof auth.state;
  user: typeof user.state;
  upload: typeof upload.state;
  process: typeof process.state;
}

const store = new Vuex.Store({
  state: simpleAppState,
  actions: {
    showSuccessSnackbar({ commit }: any, payload: string): void {
      commit('setSuccessSnackbar', { text: payload, state: true });
      setTimeout(() => {
        commit('setSuccessSnackbar', { state: false, text: '' } as Snackbar);
      }, 3000);
    },
    showFailedSnackbar({ commit }: any, payload: string): void {
      commit('setFailedSnackbar', { text: payload, state: true });
      setTimeout(() => {
        commit('setFailedSnackbar', { state: false, text: '' } as Snackbar);
      }, 3000);
    },
  },
  mutations: {
    setIsLinksDisabled(state: any, isLinksDisabled: boolean): void {
      state.isLinksDisabled = isLinksDisabled;
    },
    setSuccessSnackbar(state: any, payload: Snackbar): void {
      state.successSnackbar = { ...payload };
    },
    setFailedSnackbar(state: any, payload: Snackbar): void {
      state.failedSnackbar = { ...payload };
    },
  },
  modules: {
    auth,
    user,
    upload,
    process,
    report,
  },
});

export default store;
