/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import invertedAuthGuard from '@/router/InvertedAuthGuard';

export const authRouter = [
  {
    path: '/SignIn',
    name: 'SignIn',
    beforeEnter: invertedAuthGuard,
    component: () => import(/* webpackChunkName: "auth" */ './components/SignIn.vue'),
  },
  {
    path: '/SignInOnCreate',
    name: 'SignInOnCreate',
    beforeEnter: invertedAuthGuard,
    component: () => import(/* webpackChunkName: "auth" */ './components/SignIn.vue'),
    props: {
      onCreated: true,
    },
  },
  {
    path: '/SignUp',
    name: 'SignUp',
    beforeEnter: invertedAuthGuard,
    component: () => import(/* webpackChunkName: "auth" */ './components/SignUp.vue'),
  },
  {
    path: '/ForgotPassword',
    name: 'ForgotPassword',
    beforeEnter: invertedAuthGuard,
    component: () => import(/* webpackChunkName: "auth" */ './components/ForgotPassword.vue'),
  },
  {
    path: '/ResetPassword/:id',
    name: 'ResetPassword',
    beforeEnter: invertedAuthGuard,
    component: () => import(/* webpackChunkName: "auth" */ './components/ResetPassword.vue'),
    props: true,
  },
  {
    path: '/ActiveAccount/:id',
    name: 'ActiveAccount',
    beforeEnter: invertedAuthGuard,
    component: () => import(/* webpackChunkName: "auth" */ './components/ActivateAccount.vue'),
    props: true,
  },
];
