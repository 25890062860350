import { ListOptions } from '@/models/ListsModel';

export type ProcessFiltersState = {
  machineFilter: string;
  processNameFilter: Array<string>;
  startTimeFromFilter: string;
  startTimeToFilter: string;
  resultFilter: string;
};

export type ProcessListState = {
  filters: ProcessFiltersState;
  options: ListOptions;
};

export function initProcessListState(): ProcessListState {
  return {
    filters: {
      machineFilter: '',
      processNameFilter: [],
      startTimeFromFilter: '',
      startTimeToFilter: '',
      resultFilter: '',
    },
    options: {
      groupBy: [],
      groupDesc: [],
      itemsPerPage: 10,
      multiSort: false,
      mustSort: false,
      page: 1,
      sortBy: [],
      sortDesc: [],
    },
  };
}

const processListState = {
  filters: initProcessListState().filters,
  options: initProcessListState().options,
};

const processListMutations = {
  setFilters(state: ProcessListState, filters: ProcessFiltersState): void {
    state.filters = { ...state.filters, ...filters };
  },
  setOptions(state: ProcessListState, options: ListOptions): void {
    state.options = { ...state.options, ...options };
  },
};

export type ProcessListMutations = typeof processListMutations;
export type ProcessListMutationsKey = keyof ProcessListMutations;

export const process = {
  namespaced: true,
  state: processListState,
  mutations: processListMutations,
  actions: {},
  modules: {},
};
