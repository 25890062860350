import { TranslateResult, Values } from 'vue-i18n';

export type Tab = {
  route: string;
  text: TranslateResult;
};

const routerTabs = (
  ttFnc: (key: string, values?: Values | undefined) => TranslateResult,
): Record<string, Tab> => ({
  process: { route: 'ProcessList', text: ttFnc('Tab.Process') },
  machines: { route: 'Machines', text: ttFnc('Tab.Machines') },
  report: { route: 'Report', text: ttFnc('Tab.Report') },
});

export default routerTabs;
