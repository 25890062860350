

























import Vue from 'vue';
import { mapActions, mapState } from 'vuex';
import ChangeLocale from './ChangeLocale.vue';

export default Vue.extend({
  components: { ChangeLocale },
  computed: {
    ...mapState(['isLinksDisabled']),
  },
  methods: {
    ...mapActions('auth', ['logout']),
    onLogout() {
      this.logout().then(() => {
        this.$router.push({ name: 'SignIn' });
        this.$http.setSecurityData(null);
      });
    },
  },
});
