






























import Vue from 'vue';
import { mapActions, mapState } from 'vuex';
import routerTabs, { Tab } from '../../router/RouterTabs';

import RightMenu from './components/RightMenu.vue';
import Menu from './containers/Menu.vue';
import MenuMobile from './containers/MenuMobile.vue';

export default Vue.extend({
  components: {
    Menu,
    MenuMobile,
    RightMenu,
  },
  data() {
    return {
      delayed: false,
    };
  },
  created() {
    setTimeout(() => {
      this.delayed = true;
    }, 100);
  },

  computed: {
    ...mapState(['isLinksDisabled', 'successSnackbar']),
    ...mapState('auth', ['isAuthorized']),
    links(): Record<string, Tab> {
      return routerTabs(this.$t.bind(this));
    },
  },
  methods: {
    ...mapActions('auth', ['logout']),
    onLogout() {
      this.logout().then(() => {
        this.$router.push({ name: 'SignIn' });
        this.$http.setSecurityData(null);
      });
    },
  },
});
