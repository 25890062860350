import { ApiConfig } from './myApi';

export type SecurityDataType = { userToken: string; refreshToken: string };

const securityWorker: ApiConfig<SecurityDataType>['securityWorker'] = (
  securityData: SecurityDataType | null,
) => {
  if (!securityData?.userToken) {
    return {};
  }

  return { headers: { Authorization: `Bearer ${securityData?.userToken}` } };
};

export default securityWorker;
