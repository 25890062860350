import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

import en from 'vuetify/src/locale/en';
import pl from 'vuetify/src/locale/pl';

Vue.use(Vuetify);

export default new Vuetify({
  breakpoint: {
    mobileBreakpoint: 'sm', // This is equivalent to a value of 960
  },
  lang: {
    locales: { en, pl },
    current: 'en',
  },
  theme: {
    themes: {
      light: {
        primary: '#ed1c24',
        secondary: '#404041',
        white: '#ffffff',
        accent: '#ed1c24',
        gray: '#404041',
      },
    },
  },
});
