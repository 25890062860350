














import Vue from 'vue';

export default Vue.extend({
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
    },
    timeout: {
      type: Number,
      default: 3000,
    },
  },
});
