/* eslint-disable implicit-arrow-linebreak */
import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import EmptyWrapper from '@/shared/EmptyWrapper.vue';
import { viewerRouter } from '@/modules/viewer/router';
import Viewer from '../modules/viewer/Viewer.vue';
import { authRouter } from '@/modules/auth/router';
import invertedAuthGuard from './InvertedAuthGuard';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    redirect: '/Dashboard',
  },
  {
    path: '/Auth',
    component: EmptyWrapper,
    beforeEnter: invertedAuthGuard,
    props: true,
    children: authRouter,
  },
  {
    path: '/Viewer',
    component: Viewer,
    children: viewerRouter,
  },
  {
    path: '/ReportCheck/:id',
    name: 'ReportCheck',
    component: () =>
      import(/* webpackChunkName: "report" */ '../modules/viewer/views/ReportCheck.vue'),
    props: true,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
