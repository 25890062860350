






















import Vue from 'vue';
import { mapActions } from 'vuex';

export default Vue.extend({
  mounted() {
    this.selectedLangIndex = this.langs.findIndex((lang) => lang === this.$i18n.locale);
  },
  data() {
    return { langs: this.$i18n.availableLocales, selectedLangIndex: 0 };
  },
  methods: {
    ...mapActions(['showSuccessSnackbar', 'showFailedSnackbar']),

    changeLocale(lang: string) {
      this.$http.user
        .userLangCreate('current', { lang })
        .then(() => {
          this.showSuccessSnackbar(this.$t('Snackbar.Success'));
        })
        .catch(() => {
          this.showFailedSnackbar(this.$t('Account.Cannot save language'));
        });
      this.$i18n.locale = lang;
      this.$vuetify.lang.current = lang;
    },
  },
});
