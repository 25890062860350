import { AxiosError } from 'axios';
import store from '@/store';
import { Api } from '@/http/myApi';
import { SecurityDataType } from '@/http/SecurityWorker';

export function injectAuthInterceptor(
  api: Api<SecurityDataType>,
  refreshToken: string,
  logoutSideEffect?: () => void,
): any {
  if (!refreshToken) {
    throw new Error('Empty refresh token');
  }

  api.instance.interceptors.response.eject(0);
  api.instance.interceptors.response.use(
    (response) => response,
    async (error: AxiosError) => {
      const originalRequest = error.config;
      console.log({ error });

      if (originalRequest.url === '/auth/token/') {
        return Promise.reject(error);
      }

      if (originalRequest.url === '/auth/token/refresh/') {
        return store.dispatch('auth/logout').then(() => {
          logoutSideEffect && logoutSideEffect();
          console.log('Auto logout. Unauthorized.');
        });
      }

      if (error.response?.status === 401 && !(originalRequest as any)._retry) {
        (originalRequest as any)._retry = true;
        console.log({ originalRequest });
        return api.auth.authTokenRefreshCreate({ refresh: refreshToken }).then((response) => {
          const { access } = (response.data as unknown) as { access: string };

          api.setSecurityData({ userToken: access, refreshToken });

          store.commit('auth/setUserToken', access);

          const headers = { Authorization: `Bearer ${access}` };
          return api.instance({
            ...originalRequest,
            headers: { ...originalRequest.headers, ...headers },
          });
        });
      }

      return Promise.reject(error);
    },
  );
}
