import { NavigationGuard, NavigationGuardNext, Route } from 'vue-router';
import store, { AppState } from '@/store';

const authGuard: NavigationGuard = (to: Route, from: Route, next: NavigationGuardNext) => {
  if ((store.state as AppState).auth.isAuthorized) {
    next();
  } else {
    next({
      name: 'SignIn',
      query: { redirect: to.path },
    });
  }
};

export default authGuard;
