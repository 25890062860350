/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface CustomTokenObtainPair {
  email: string;
  password: string;
}

export interface TokenRefresh {
  refresh: string;
}

export type DashboardSerialiser = object;

export interface MachineListSerialiser {
  /** @format uuid */
  uuid?: string;
  serial_number: string;
  user_comment?: string;

  /**
   * @min 0
   * @max 65535
   */
  machine_version?: number;
  is_demo?: boolean;
  friendly_name?: string;
  machine_name?: string;
  max_execution_counter: number;
}

export interface MahcineUserCommentSerializer {
  user_comment?: string;
}

export interface CheckSerialiser {
  check_sums: string[];
}

export interface Name {
  /** @format uuid */
  names_and_time: string;
}

export interface Date {
  date_process: Name;
}

export interface ProcessTree {
  machines: Date;
}

export interface MachineSerialiser {
  /** @format uuid */
  uuid?: string;
  serial_number: string;
  user_comment?: string;

  /**
   * @min 0
   * @max 65535
   */
  machine_version?: number;
  is_demo?: boolean;
  friendly_name?: string;
  machine_name?: string;
}

export interface ExecutionDetailsSerialiser {
  /** @format uuid */
  uuid?: string;
  process_type?: number;
  process_type_name?: string;
  process_type_fullname?: string;
  start_date?: string;
  end_date?: string;
  file_name?: string;
  upload_session?: string;
  seconds_taken?: number;

  /** @format decimal */
  firmware_version?: string;
  user_comment?: string;
  end_status_codes?: object;
  execution_counter?: number;
  insert_weight?: number;
  water_data_reliable?: boolean;
  sha?: string;
  load_status?: number;

  /** @format date-time */
  upload_date?: string;
  machine?: MachineSerialiser;
  process_state_list?: string;
}

export interface ProcessUserCommentSerializer {
  user_comment?: string;
}

export interface ReportSerialiser {
  /** @format uuid */
  uuid?: string;

  /** @format date-time */
  created_date?: string;

  /** @format date-time */
  begin_date: string;

  /** @format date-time */
  end_date: string;
  machine?: MachineSerialiser;
}

export interface ReportDetailsSerialiser {
  /** @format uuid */
  uuid?: string;

  /** @format date-time */
  created_date?: string;

  /** @format date-time */
  begin_date: string;

  /** @format date-time */
  end_date: string;
  snapshot?: object;

  /** @format uuid */
  machine_uuid: string;
}

export interface CreateUser {
  /** @format email */
  email: string;
  password: string;
  first_name?: string;
  last_name?: string;
  company_name?: string | null;
  address_line1?: string | null;
  address_line2?: string | null;
  nip?: string | null;
  phone?: string | null;

  /**
   * @min -2147483648
   * @max 2147483647
   */
  business_type?: number | null;
  lang?: string;
  accept_policy: boolean;
  metric_units?: boolean;
}

export interface ActiveUser {
  token: string;
}

export interface Email {
  /** @format email */
  email: string;
}

export interface PasswordToken {
  password: string;
  token: string;
}

export interface Token {
  token: string;
}

export interface GetUser {
  id?: number;

  /** @format email */
  email: string;
  first_name?: string;
  last_name?: string;
  company_name?: string | null;
  address_line1?: string | null;
  address_line2?: string | null;
  nip?: string | null;
  phone?: string | null;

  /**
   * @min -2147483648
   * @max 2147483647
   */
  business_type?: number | null;
  lang?: string;
  metric_units?: boolean;
}

export interface UpdateUser {
  first_name?: string;
  last_name?: string;
  company_name?: string | null;
  address_line1?: string | null;
  address_line2?: string | null;
  nip?: string | null;
  phone?: string | null;

  /**
   * @min -2147483648
   * @max 2147483647
   */
  business_type?: number | null;
  lang?: string;
  metric_units?: boolean;
}

export interface LangUser {
  lang?: string;
}

export interface MetricUnitsUser {
  metric_units?: boolean;
}

export interface PasswordUser {
  old_password: string;
  new_password: string;
}

import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, ResponseType } from "axios";

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams extends Omit<AxiosRequestConfig, "data" | "params" | "url" | "responseType"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> extends Omit<AxiosRequestConfig, "data" | "cancelToken"> {
  securityWorker?: (
    securityData: SecurityDataType | null,
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
  secure?: boolean;
  format?: ResponseType;
}

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
}

export class HttpClient<SecurityDataType = unknown> {
  instance: AxiosInstance;
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private secure?: boolean;
  private format?: ResponseType;

  constructor({ securityWorker, secure, format, ...axiosConfig }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({ ...axiosConfig, baseURL: axiosConfig.baseURL || "http://localhost:8000" });
    this.secure = secure;
    this.format = format;
    this.securityWorker = securityWorker;
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  private mergeRequestParams(params1: AxiosRequestConfig, params2?: AxiosRequestConfig): AxiosRequestConfig {
    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.instance.defaults.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<AxiosResponse<T>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const responseFormat = (format && this.format) || void 0;

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(type && type !== ContentType.FormData ? { "Content-Type": type } : {}),
        ...(requestParams.headers || {}),
      },
      params: query,
      responseType: responseFormat,
      data: body,
      url: path,
    });
  };
}

/**
 * @title Enbio Data API
 * @version v1
 * @license BSD License
 * @termsOfService https://www.google.com/policies/terms/
 * @baseUrl http://localhost:8000
 *
 * Description
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  auth = {
    /**
     * No description
     *
     * @tags auth
     * @name AuthTokenCreate
     * @request POST:/auth/token/
     * @secure
     */
    authTokenCreate: (data: CustomTokenObtainPair, params: RequestParams = {}) =>
      this.request<CustomTokenObtainPair, any>({
        path: `/auth/token/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Takes a refresh type JSON web token and returns an access type JSON web token if the refresh token is valid.
     *
     * @tags auth
     * @name AuthTokenRefreshCreate
     * @request POST:/auth/token/refresh/
     * @secure
     */
    authTokenRefreshCreate: (data: TokenRefresh, params: RequestParams = {}) =>
      this.request<TokenRefresh, any>({
        path: `/auth/token/refresh/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  dashboard = {
    /**
     * No description
     *
     * @tags dashboard
     * @name DashboardTypes
     * @request GET:/dashboard/types/
     * @secure
     */
    dashboardTypes: (params: RequestParams = {}) =>
      this.request<DashboardSerialiser[], any>({
        path: `/dashboard/types/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags dashboard
     * @name DashboardVersion
     * @request GET:/dashboard/version/
     * @secure
     */
    dashboardVersion: (params: RequestParams = {}) =>
      this.request<DashboardSerialiser[], any>({
        path: `/dashboard/version/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags dashboard
     * @name DashboardWeeks
     * @request GET:/dashboard/weeks/
     * @secure
     */
    dashboardWeeks: (params: RequestParams = {}) =>
      this.request<DashboardSerialiser[], any>({
        path: `/dashboard/weeks/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  machine = {
    /**
     * No description
     *
     * @tags machine
     * @name MachineList
     * @request GET:/machine/
     * @secure
     */
    machineList: (
      query?: { machine?: string; ordering?: string; page?: number; page_size?: number },
      params: RequestParams = {},
    ) =>
      this.request<
        { count: number; next?: string | null; previous?: string | null; results: MachineListSerialiser[] },
        any
      >({
        path: `/machine/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags machine
     * @name MachineUserComment
     * @request POST:/machine/{id}/user_comment/
     * @secure
     */
    machineUserComment: (id: string, data: MahcineUserCommentSerializer, params: RequestParams = {}) =>
      this.request<MahcineUserCommentSerializer, any>({
        path: `/machine/${id}/user_comment/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  process = {
    /**
     * No description
     *
     * @tags process
     * @name ProcessList
     * @request GET:/process/
     * @secure
     */
    processList: (
      query?: {
        ordering?: string;
        start_date_begin?: string;
        start_date_end?: string;
        process_type?: string;
        machine?: string;
        load_status?: string;
        end_status_codes?: string;
        page?: number;
        page_size?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/process/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags process
     * @name ProcessCheckCreate
     * @request POST:/process/check/
     * @secure
     */
    processCheckCreate: (data: CheckSerialiser, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/process/check/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags process
     * @name ProcessStatsList
     * @request GET:/process/stats/
     * @secure
     */
    processStatsList: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/process/stats/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Get stats by X-UploadSesionId
     *
     * @tags process
     * @name ProcessStatsRead
     * @request GET:/process/stats/{id}/
     * @secure
     */
    processStatsRead: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/process/stats/${id}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags process
     * @name ProcessTreeList
     * @request GET:/process/tree/
     * @secure
     */
    processTreeList: (query?: { page?: number; page_size?: number }, params: RequestParams = {}) =>
      this.request<{ count: number; next?: string | null; previous?: string | null; results: ProcessTree[] }, any>({
        path: `/process/tree/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags process
     * @name ProcessUploadCreate
     * @request POST:/process/upload/
     * @secure
     */
    processUploadCreate: (data: { file?: File; file1?: File; file_xyz?: File }, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/process/upload/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.FormData,
        ...params,
      }),

    /**
     * No description
     *
     * @tags process
     * @name ProcessRead
     * @request GET:/process/{uuid}/
     * @secure
     */
    processRead: (uuid: string, params: RequestParams = {}) =>
      this.request<ExecutionDetailsSerialiser, any>({
        path: `/process/${uuid}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags process
     * @name ProcessDetails
     * @request GET:/process/{uuid}/details/
     * @secure
     */
    processDetails: (uuid: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/process/${uuid}/details/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags process
     * @name ProcessSummary
     * @request GET:/process/{uuid}/summary/
     * @secure
     */
    processSummary: (uuid: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/process/${uuid}/summary/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags process
     * @name ProcessUserComment
     * @request POST:/process/{uuid}/user_comment/
     * @secure
     */
    processUserComment: (uuid: string, data: ProcessUserCommentSerializer, params: RequestParams = {}) =>
      this.request<ProcessUserCommentSerializer, any>({
        path: `/process/${uuid}/user_comment/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  reports = {
    /**
     * No description
     *
     * @tags reports
     * @name ReportsList
     * @request GET:/reports/
     * @secure
     */
    reportsList: (query?: { ordering?: string; page?: number; page_size?: number }, params: RequestParams = {}) =>
      this.request<{ count: number; next?: string | null; previous?: string | null; results: ReportSerialiser[] }, any>(
        {
          path: `/reports/`,
          method: "GET",
          query: query,
          secure: true,
          format: "json",
          ...params,
        },
      ),

    /**
     * No description
     *
     * @tags reports
     * @name ReportsCreate
     * @request POST:/reports/
     * @secure
     */
    reportsCreate: (data: ReportDetailsSerialiser, params: RequestParams = {}) =>
      this.request<ReportDetailsSerialiser, any>({
        path: `/reports/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags reports
     * @name ReportsLastReportDate
     * @request GET:/reports/last_report_date/
     * @secure
     */
    reportsLastReportDate: (query?: { ordering?: string }, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/reports/last_report_date/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags reports
     * @name ReportsRead
     * @request GET:/reports/{uuid}/
     * @secure
     */
    reportsRead: (uuid: string, params: RequestParams = {}) =>
      this.request<ReportDetailsSerialiser, any>({
        path: `/reports/${uuid}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags reports
     * @name ReportsHtml
     * @request GET:/reports/{uuid}/html/
     * @secure
     */
    reportsHtml: (uuid: string, params: RequestParams = {}) =>
      this.request<ReportDetailsSerialiser, any>({
        path: `/reports/${uuid}/html/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags reports
     * @name ReportsPdf
     * @request GET:/reports/{uuid}/pdf/
     * @secure
     */
    reportsPdf: (uuid: string, params: RequestParams = {}) =>
      this.request<ReportDetailsSerialiser, any>({
        path: `/reports/${uuid}/pdf/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  system = {
    /**
     * @description id = true for clean up Machines, Processes, files. Users will keep.
     *
     * @tags system
     * @name SystemDelete
     * @request DELETE:/system/{id}/
     * @secure
     */
    systemDelete: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/system/${id}/`,
        method: "DELETE",
        secure: true,
        ...params,
      }),
  };
  user = {
    /**
     * @description API endpoint that allows users to be viewed or edited.
     *
     * @tags user
     * @name UserCreate
     * @request POST:/user/
     * @secure
     */
    userCreate: (data: CreateUser, params: RequestParams = {}) =>
      this.request<CreateUser, void>({
        path: `/user/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description API endpoint that allows users to be viewed or edited.
     *
     * @tags user
     * @name UserUserActivate
     * @request POST:/user/activate/
     * @secure
     */
    userUserActivate: (data: ActiveUser, params: RequestParams = {}) =>
      this.request<ActiveUser, any>({
        path: `/user/activate/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Sends a signal reset_password_token_created when a reset token was created
     *
     * @tags user
     * @name UserPasswordResetCreate
     * @summary An Api View which provides a method to request a password reset token based on an e-mail address
     * @request POST:/user/password_reset/
     * @secure
     */
    userPasswordResetCreate: (data: Email, params: RequestParams = {}) =>
      this.request<Email, any>({
        path: `/user/password_reset/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description An Api View which provides a method to reset a password based on a unique token
     *
     * @tags user
     * @name UserPasswordResetConfirmCreate
     * @request POST:/user/password_reset/confirm/
     * @secure
     */
    userPasswordResetConfirmCreate: (data: PasswordToken, params: RequestParams = {}) =>
      this.request<PasswordToken, any>({
        path: `/user/password_reset/confirm/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description An Api View which provides a method to verify that a token is valid
     *
     * @tags user
     * @name UserPasswordResetValidateTokenCreate
     * @request POST:/user/password_reset/validate_token/
     * @secure
     */
    userPasswordResetValidateTokenCreate: (data: Token, params: RequestParams = {}) =>
      this.request<Token, any>({
        path: `/user/password_reset/validate_token/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description API endpoint that allows users to be viewed or edited.
     *
     * @tags user
     * @name UserRead
     * @request GET:/user/{user_id}/
     * @secure
     */
    userRead: (userId: string, params: RequestParams = {}) =>
      this.request<GetUser, any>({
        path: `/user/${userId}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description API endpoint that allows users to be viewed or edited.
     *
     * @tags user
     * @name UserUpdate
     * @request PUT:/user/{user_id}/
     * @secure
     */
    userUpdate: (userId: string, data: UpdateUser, params: RequestParams = {}) =>
      this.request<UpdateUser, any>({
        path: `/user/${userId}/`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description API endpoint that allows users to be viewed or edited.
     *
     * @tags user
     * @name UserLangRead
     * @request GET:/user/{user_id}/lang/
     * @secure
     */
    userLangRead: (userId: string, params: RequestParams = {}) =>
      this.request<LangUser, any>({
        path: `/user/${userId}/lang/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description API endpoint that allows users to be viewed or edited.
     *
     * @tags user
     * @name UserLangCreate
     * @request POST:/user/{user_id}/lang/
     * @secure
     */
    userLangCreate: (userId: string, data: LangUser, params: RequestParams = {}) =>
      this.request<LangUser, any>({
        path: `/user/${userId}/lang/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description API endpoint that allows users to be viewed or edited.
     *
     * @tags user
     * @name UserMetricUnitsRead
     * @request GET:/user/{user_id}/metric_units/
     * @secure
     */
    userMetricUnitsRead: (userId: string, params: RequestParams = {}) =>
      this.request<MetricUnitsUser, any>({
        path: `/user/${userId}/metric_units/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description API endpoint that allows users to be viewed or edited.
     *
     * @tags user
     * @name UserMetricUnitsCreate
     * @request POST:/user/{user_id}/metric_units/
     * @secure
     */
    userMetricUnitsCreate: (userId: string, data: MetricUnitsUser, params: RequestParams = {}) =>
      this.request<MetricUnitsUser, any>({
        path: `/user/${userId}/metric_units/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description API endpoint that allows users to be viewed or edited.
     *
     * @tags user
     * @name UserUserPassword
     * @request POST:/user/{user_id}/password/
     * @secure
     */
    userUserPassword: (userId: string, data: PasswordUser, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/user/${userId}/password/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
}
