import Vue from 'vue';
import uploader from 'vue-simple-uploader';

import HighchartsVue from 'highcharts-vue';
import NoDataModule from 'highcharts/modules/no-data-to-display.js';
import Highcharts from 'highcharts';
import HCSoldGauge from 'highcharts/modules/solid-gauge';
import * as ChartModuleMore from 'highcharts/highcharts-more';
import annotationsInit from 'highcharts/modules/annotations';

import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store, { AppState } from './store';
import vuetify from './plugins/vuetify';
import i18n from './i18n';

import { Api } from './http/myApi';
import securityWorker, { SecurityDataType } from './http/SecurityWorker';
import { injectAuthInterceptor } from './modules/auth/services/auth.interceptor';

import Container from './modules/viewer/containers/Container.vue';
import BasicSnackbar from './shared/BasicSnackbar.vue';
import SuccessSnackbar from './shared/SuccessSnackbar.vue';
import FailSnackbar from './shared/FailSnackbar.vue';

Vue.config.productionTip = false;

Vue.use(uploader);

(ChartModuleMore as any)(Highcharts);
HCSoldGauge(Highcharts);
NoDataModule(Highcharts);
annotationsInit(Highcharts);

Vue.use(HighchartsVue);

Vue.component('container', Container);
Vue.component('basic-snackbar', BasicSnackbar);
Vue.component('success-snackbar', SuccessSnackbar);
Vue.component('fail-snackbar', FailSnackbar);

const api = new Api({ securityWorker, baseURL: process.env.VUE_APP_API_URL });
Vue.prototype.$http = api;

store.dispatch('auth/autoLogin').then(() => {
  const { userToken, refreshToken } = (store.state as AppState).auth;

  if (userToken && refreshToken) {
    api.setSecurityData({ userToken, refreshToken });

    injectAuthInterceptor(api, refreshToken, () => router.push({ name: 'SignIn' }));

    api.user.userRead('current').then((resp) => {
      store.dispatch('user/assignUser', resp.data);

      const lang = resp.data.lang;
      if (lang) {
        i18n.locale = lang;

        if (vuetify.userPreset.lang) {
          vuetify.userPreset.lang.current = lang;
          vuetify.preset.lang.current = lang;
          vuetify.framework.lang.current = lang;
        }
      }

      store.commit('user/setSettings', { metricUnits: resp.data.metric_units });
    });
  }
});

declare module 'vue/types/vue' {
  // eslint-disable-next-line no-shadow
  interface Vue {
    $http: Api<SecurityDataType>;
  }
}

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
