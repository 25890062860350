export const getInitialLanguage: () => string | undefined = () => {
  const availableLanguages = process.env.VUE_APP_I18N_LANGUAGES;
  const defaultLocale = process.env.VUE_APP_I18N_LOCALE;

  if (!availableLanguages || !availableLanguages.length) {
    throw new Error('No available languages setted!');
  }

  if (!defaultLocale) {
    throw new Error('No default language setted');
  }

  if (navigator.language) {
    const browserLanguage = navigator.language.slice(0, 2);

    if (availableLanguages?.includes(browserLanguage)) {
      return browserLanguage;
    } else return defaultLocale;
  }
};
