import { uploadActions } from './uploadActions';

export type UploadStats = {
  filesNumber: number;
  uploadedFiles: number;
  duplicatedFiles: number;
  finishedFiles: number;
  incorrectFiles: number;
  incorrectFilesAsync: number;
  processingFilesAsync: number;
  duplicatedFilesAsync: number;
  initialProcessingFiles: boolean;
  processingFiles: boolean;
};

export function initialStatsState(): UploadStats {
  return {
    filesNumber: 0,
    uploadedFiles: 0,
    duplicatedFiles: 0,
    finishedFiles: 0,
    incorrectFiles: 0,
    incorrectFilesAsync: 0,
    processingFilesAsync: 0,
    duplicatedFilesAsync: 0,
    initialProcessingFiles: false,
    processingFiles: false,
  };
}

const uploadState = {
  stats: initialStatsState(),
};

export type UploadState = typeof uploadState;

const uploadMutations = {
  setUploadStatistics(state: UploadState, stats: UploadStats): void {
    state.stats = { ...stats };
  },
  resetUploadStatistics(state: UploadState): void {
    state.stats = { ...initialStatsState() };
  },
};

export type UploadMutations = typeof uploadMutations;
export type UploadMutationKey = keyof UploadMutations;
export type UploadCommit = (key: UploadMutationKey, payload: any) => void;

export const upload = {
  namespaced: true,
  state: uploadState,
  mutations: uploadMutations,
  actions: {
    ...uploadActions,
  },
  modules: {},
};
