import { NavigationGuard, NavigationGuardNext, Route } from 'vue-router';
import store, { AppState } from '@/store';

const invertedAuthGuard: NavigationGuard = (to: Route, from: Route, next: NavigationGuardNext) => {
  if ((store.state as AppState).auth.isAuthorized) {
    next({ name: 'Dashboard' });
  } else {
    next();
  }
};

export default invertedAuthGuard;
