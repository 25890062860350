


















import { Tab } from '@/router/RouterTabs';
import Vue, { PropType } from 'vue';

export default Vue.extend({
  props: {
    isLinksDisabled: {
      type: Boolean,
      required: true,
    },
    links: {
      type: Object as PropType<Record<string, Tab>>,
      required: true,
    },
  },
  computed: {
    hideSlider(): boolean {
      return this.$route.name === 'Account';
    },
  },
});
