import authGuard from '@/router/AuthGuard';

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export const viewerRouter = [
  {
    path: '/Account',
    name: 'Account',
    beforeEnter: authGuard,
    component: () => import(/* webpackChunkName: "account" */ './account/Account.vue'),
  },
  {
    path: '/Dashboard',
    name: 'Dashboard',
    beforeEnter: authGuard,
    component: () => import(/* webpackChunkName: "viewer" */ './views/Dashboard.vue'),
    props: true,
  },
  {
    path: '/Process',
    beforeEnter: authGuard,
    component: () => import(/* webpackChunkName: "viewer" */ './containers/ProcessWrapper.vue'),
    props: true,
    children: [
      {
        path: '',
        name: 'ProcessList',
        component: () => import(/* webpackChunkName: "viewer" */ './views/ProcessList.vue'),
        props: true,
      },
      {
        path: ':id',
        name: 'Process',
        component: () => import(/* webpackChunkName: "viewer" */ './views/ProcessSingle.vue'),
        props: true,
      },
    ],
  },
  {
    path: '/Machines',
    name: 'Machines',
    beforeEnter: authGuard,
    component: () => import(/* webpackChunkName: "viewer" */ './views/Machines.vue'),
  },
  {
    path: '/Upload',
    name: 'Upload',
    beforeEnter: authGuard,
    component: () => import(/* webpackChunkName: "upload" */ './views/Upload.vue'),
  },
  {
    path: '/Report',
    name: 'Report',
    component: () => import(/* webpackChunkName: "report" */ './containers/ReportWrapper.vue'),
    props: true,
    redirect: { name: 'ReportList' },
    children: [
      {
        path: 'ReportList',
        name: 'ReportList',
        component: () => import(/* webpackChunkName: "report" */ './views/ReportList.vue'),
        beforeEnter: authGuard,
        props: true,
      },
      {
        path: 'Details/:id',
        name: 'ReportDetails',
        component: () => import(/* webpackChunkName: "report" */ './views/Report.vue'),
        props: true,
      },
      {
        path: 'New',
        name: 'ReportNew',
        beforeEnter: authGuard,
        component: () => import(/* webpackChunkName: "report" */ './views/ReportNew.vue'),
        props: true,
      },
    ],
  },
];
