import { GetUser } from '@/http/myApi';

const userState = {
  user: null as GetUser | null,
  email: '',
  userForm: {
    businessType: null as number | null,
  },
  settings: {
    metricUnits: true,
  },
};
export type UserState = typeof userState;

const userMutations = {
  setCurrentUser(state: UserState, user: GetUser): void {
    state.user = { ...user };
  },
  setUserEmail(state: UserState, email: string): void {
    state.email = email;
  },
  setUserForm(state: UserState, userForm: any): void {
    state.userForm = { ...userForm };
  },
  setSettings(state: UserState, settings: { metricUnits: boolean }): void {
    state.settings = { ...settings };
  },
};

export type UserMutations = typeof userMutations;
type UserMutationKey = keyof UserMutations;
type UserCommit = (key: UserMutationKey, payload: any) => void;

const userGetters = {
  isUserFullReportData: (state: UserState) => {
    const user = state.user;
    return !!(
      user?.company_name &&
      user?.business_type !== null &&
      user?.business_type !== undefined &&
      user.address_line1 &&
      user.address_line2 &&
      user.nip &&
      user.phone
    );
  },
};
export type UserGetters = typeof userGetters;

const userActions = {
  assignUser({ commit }: { commit: UserCommit }, user: GetUser): void {
    commit('setCurrentUser', user);

    const userForm = {
      businessType: user.business_type,
    };
    commit('setUserForm', userForm);
  },
};

export type UserActions = typeof userActions;

export const user = {
  namespaced: true,
  state: userState,
  mutations: userMutations,
  actions: userActions,
  getters: userGetters,
  modules: {},
};

export type UserStore = typeof user;
