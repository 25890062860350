













import Vue from 'vue';
import { mapState } from 'vuex';

export default Vue.extend({
  computed: {
    ...mapState(['successSnackbar', 'failedSnackbar']),
  },
  data() {
    return {
      version: '',
    };
  },

  mounted() {
    this.$http.dashboard.dashboardVersion().then((resp) => {
      this.version = (resp.data as any).version;
    });
  },
});
