const authState = {
  isAuthorized: false,
  userToken: '',
  refreshToken: '',
  user: null,
  signupForm: {
    businessType: null as number | null,
  },
};
export type AuthState = typeof authState;

const authMutations = {
  setUserToken(state: AuthState, userToken: string): void {
    state.userToken = userToken;
    if (userToken) {
      localStorage.setItem('userToken', userToken);
    }
  },
  setRefreshToken(state: AuthState, refreshToken: string): void {
    state.refreshToken = refreshToken;
    if (refreshToken) {
      localStorage.setItem('refreshToken', refreshToken);
    }
  },
  setAuthorized(state: AuthState, isAuthorized: boolean): void {
    state.isAuthorized = isAuthorized;
  },
  setSignupForm(state: AuthState, signupForm: any): void {
    state.signupForm = { ...signupForm };
  },
};

export type AuthMutations = typeof authMutations;
type AuthMutationKey = keyof AuthMutations;
type AuthCommit = (key: AuthMutationKey, payload: any) => void;

export type AuthData = { userToken: string; refreshToken: string };

export const auth = {
  namespaced: true,
  state: authState,
  mutations: authMutations,
  actions: {
    authUser(
      {
        commit,
      }: {
        commit: AuthCommit;
      },
      authData: AuthData,
    ): void {
      commit('setUserToken', authData.userToken);
      commit('setRefreshToken', authData.refreshToken);
      commit('setAuthorized', true);
    },
    autoLogin({ commit }: { commit: AuthCommit }): void {
      const userToken = localStorage.getItem('userToken');
      const refreshToken = localStorage.getItem('refreshToken');

      if (userToken && refreshToken) {
        commit('setUserToken', userToken);
        commit('setRefreshToken', refreshToken);
        commit('setAuthorized', true);
      }
    },
    logout({ commit }: { commit: AuthCommit }): void {
      localStorage.removeItem('userToken');
      localStorage.removeItem('refreshToken');

      commit('setUserToken', null);
      commit('setRefreshToken', null);
      commit('setAuthorized', false);
    },
  },
  modules: {},
};
